/* -----responsive----- */

/* ==========Testimonial start======================= */



.Testmonial-main-div {
  display: flex;
  margin: 20px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 0.1px solid #d81d49a0;
  border-radius: 4px;
  line-height: 1.4;
  letter-spacing: 1px;
  box-shadow: 36px 36px 72px #d81d499d;

}

.Testmonial-main-div-inner-left {
  padding: 5px;
  margin: 5px;
}

.Testmonial-main-div-inner-right {
  padding: 5px;
  margin: 5px;
}

/* ==========Testimonial end======================= */



@media screen and (max-width:799px) {

  /* header */

  #header-lists {
    position: relative;
    top: -58px !important;
  }

  #header-list-form {
    position: relative;
    top: -56px !important;
  }

  .arrow-icon-1 {
    position: absolute;
    /* top: -27px; */
    right: 83% !important;
  }

  .arrow-icon-2 {
    right: 59% !important;
  }

  .arrow-icon-3 {
    right: 33% !important;
  }

  .arrow-icon-4 {
    right: 8% !important;
  }

  #homeHeader-video {
    object-fit: none !important;
  }


  .bqyzQp {
    width: 100% !important;
  }

  .qFTwe {
    padding-left: 0px !important;
  }

  .bghjHX {
    width: 100% !important;

  }

  .tourpack-input-header {
    width: 100% !important;
  }



  .boxes {
    display: flex;
    flex-direction: column;
  }

  .field-select-1 {
    width: 100%;
  }

  .Activity-inputfield {
    width: 100% !important;
  }

  .form-cn {
    width: 100% !important;
    margin: 0 !important;
    /* display: inline !important; */
  }

  #field-destination-boxes {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .fields-destination-cont {
    position: absolute;
    left: 0 !important;
  }

  .field-destination-1 {
    width: 100%;
  }

  .field-destination {
    width: 100%;
  }

  .field-date {
    width: 100%;
  }

  .field-select {
    width: 100%;
  }

  .bHxoXI {
    padding-left: 0px !important;
    width: 106% !important;
  }

  .guests-part {
    position: relative;
    top: -140px;
    width: 180px !important;

  }

  .guests-parts-mb {
    margin-bottom: 3px !important;
  }


  /* explore the world */


  .bWFaMA:last-of-type> :last-of-type {
    padding-right: 0px !important;
    margin-right: 0px !important;
  }

  .bWFaMA {
    width: 110% !important;
  }

  .packages {
    height: 100%;
    width: 101%;
    border-radius: 15px !important;
  }

  .package-img {
    width: 100% !important;
    margin-top: 10px !important;
    height: 170px !important;
  }

  #explore-world-carousel {
    margin-bottom: 0px !important;
  }

  /* .hLycdF {
    padding-left: 0px !important;
    width: 110% !important;
  }
*/
  .kaka::before {
    width: 94%;
  }

  .mamu::before {
    width: 94%;
  }

  .chacha::before {
    width: 90%;
  }

  .cat {
    position: absolute;
    top: -88px !important;
    z-index: 9;
    right: 0px;
    width: 100% !important;
  }


  #home-carousel-mainDiv {
    margin-bottom: 5px !important;
  }


  /* things-to-do */

  #things-todo-main {
    margin-bottom: 72px !important;
  }

  #things-todo-heading {
    margin-bottom: 8px !important;
  }

  .things-to-do-3rd {
    padding: 0px 10px !important;
  }

  .iFEUIE {
    width: 112% !important;
    padding: 5px !important;
  }

  .iFEUIE:last-of-type> :last-of-type {
    padding-right: 0px !important;
    margin-right: 0px !important;
  }


  /* top-destination */

  #top-sight-main {
    margin-bottom: 20px !important;
  }

  #top-destination-main {
    margin-bottom: 0px !important;
  }

  .top-destination-heading {
    margin-bottom: 7px !important;
  }

  /* tourPackages */

  #tourPackages-container {
    margin-bottom: 8px !important;
  }

  .jxzrEK {
    padding-left: 0px !important;
    width: 111% !important;
  }


  .fpaZMI {
    padding-left: 3px !important;
    width: 100% !important;
  }

  .bMcwED {
    padding-left: 0 !important;
    width: 100% !important;
  }

  .doJiSR {
    padding-left: 0px !important;
    width: 100% !important;
  }

  #discount-carousel-main {
    margin-bottom: -75px !important;
  }

  .discount-carousel {
    padding: 0px 10px;
    height: 100% !important;

  }

  .heaaOs {
    padding-left: 0px !important;
    width: 110%;
  }

  .gqFfkb:last-of-type> :last-of-type {
    padding-right: 0px !important;
    margin-right: 0px !important;
  }

  /* top-sight-heading */

  .top-sight-heading {
    margin-bottom: 8px !important;
  }

  .top-sight {
    margin-bottom: 5px !important;
  }

  .top-sight img {
    width: 100% !important;
  }

  .top-sight-packages {
    justify-content: space-evenly !important;
  }

  .top-sight-packages-text {
    padding: 10px !important;
  }

  /* .sight-button{
    padding-left: 200px;
  } */

  /* top-branding */

  #top-branding-main {
    margin-bottom: 10px !important;
  }

  .top-branding-heading {
    margin-bottom: 8px !important;
  }

  .top-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top-hotels-city {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: start;
  }



  /* testimonial */


  /* ==========Testimonial start======================= */

  .Testmonial-main-div {
    display: flex;
    flex-direction: column;

  }

  #testimonial-mb {
    margin-bottom: 25px !important;
  }


  /* ==========Testimonial end======================= */



  .testi {
    width: 100% !important;
  }

  .testi-carousel {
    width: 100% !important;
  }

  .cudUqG {
    display: flex !important;
    width: 100%;
  }

  .testi-carousel-div {
    gap: 40px !important;
    display: flex;
    flex-direction: column;

  }

  .testi-carousel-div img {
    height: 75px !important;
    width: 75px !important;
  }

  .testi-carousel-innerdiv1 {
    width: 20% !important;
  }

  .testi-carousel-innerdiv2 {
    padding: 10px;
    width: 80% !important;
  }

  .dQMmGc {
    width: 100% !important;
  }

  /* activity-packages */

  .igrZou:last-of-type> :last-of-type {
    padding-right: 0px !important;
    margin-right: 0px !important;
  }

  .activity-img {
    width: 100% !important;
  }



  /* ------------home-mobile-app--------------------- */

  .home-mobile-app {
    gap: 0px !important;
  }


  /* footer--------- */

  .footer-container {
    padding: 20px !important;
  }

  .footer-aboutus-img {
    width: 25% !important;
    height: 80px !important;
  }

  .tips-head {
    margin-bottom: 10px !important;
  }

  .footer1-heading {
    margin-bottom: 5px !important;
  }

  .footer1-maindiv {
    gap: 10px;
  }

  .footer-nav {
    margin-bottom: 0px !important;
  }

  .footer-social-icons {
    gap: 20px !important;
  }


  .footer-1 {
    padding: 10px 0px !important;
  }

  .footer-heading {
    margin-bottom: 0px !important;
  }

  .footer-main2 {
    gap: 10px !important;
  }



  /* arrangement */

  .arrangement-images-part {
    gap: 10px !important;
  }

  .aggreement-img {
    width: 100% !important;
  }

  /* -----------Multiple-Activity----------------- */

  .multiple-activity-top {
    height: 180px !important;
  }

  .Multiple-Activity-mb {
    margin-bottom: 10px !important;
  }

  .bg-yellow img {
    width: 100% !important;
    height: 100%;
    border-radius: 5px;
  }

  /* ---------Hotel-Details----------- */

  .hotelDetails-content {
    padding: 5px !important;
  }

  .HotelDetails-Accordion {
    width: 100% !important;
  }

  .HotelDetails-mb {
    margin-bottom: 10px !important;
  }

  /* .dnasj_01 p {
    font-size: 25px !important;
    line-height: 30px !important;
} */


  #hotel-review-hotel-bok {
    height: 98% !important;
  }

  .hotel-review-image {
    height: 100% !important;
    width: 100% !important;
    border-radius: 5px;
  }

  .hotel-review-bok-details {
    margin-bottom: 0px !important;
  }





  /* ---page2 --> Stays---- */

  .hotel-top-bgimg {
    height: 390px !important;
  }

  #hotel-stays-top-icon {
    position: absolute;
    left: 27% !important;
    top: 36px !important;
  }

  #hotel-stay-destination {
    position: absolute;
    left: 2% !important;
    top: 235px !important;
    width: 96% !important;
  }

  /* ---page3 --> Taxi---- */


  .taxi-top-activity {
    background-attachment: local !important;
  }

  #taxi-bg::before {
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 60%) !important;
    height: 472px !important;
  }

  .geh {
    position: absolute;
    top: -175px !important;
    width: 94% !important;
  }

  .jitotrg {
    margin-bottom: 297px;
  }

  .ul_wrap {
    margin: 0px 0px 15px 0px !important;
    align-items: center;
    border-radius: 10px !important;
  }

  .ul_wrap li {
    margin: 6px 0px !important;
    padding: 4px;

  }

  #ul_wrap-Dailyride {
    padding: 11px !important;
    font-size: 12px;
  }


  /* ---------part4 --> Activities--------- */

  .activity-top-img {
    height: 180px !important;
  }

  .jtbEIk {
    width: 111% !important;
  }

  .activities-div-mb {
    margin-bottom: 10px !important;
  }

  .activities-p-mb {
    margin-bottom: 0px !important;
  }

  .activity-cart {
    width: 100% !important;
  }

  .trekking-div-mb {
    margin-bottom: 0px !important;
  }

  /* .buggee-jump0ffer{
    width: 60%;
  } */

  .activityDetails-top {
    height: 180px !important;
  }

  .ActivityDetails-accordian {
    width: 100% !important;
    margin-top: 0px !important;
  }

  .activityDetails-img {
    width: 100% !important;
  }

  .activityDetails-div-mb {
    margin-bottom: 10px !important;
  }

  #activityDetails-bread {
    position: relative;
    left: 107px;
    top: 56px;
    width: 60%;
  }

  .ActivityReview-image {
    width: 100% !important;
    border-radius: 8px;
  }






  /* -------part4 --> Tourpackages--------- */

  .tripPackages-top {
    height: 180px !important;
    background-attachment: local !important;
  }

  #tripPackages-bread {
    position: absolute;
    left: 89px;
    top: 60px;
  }

  .tourpackages {
    margin-bottom: 0px !important;
  }

  .trippackages-details-mb {
    margin-bottom: 10px !important;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 180px !important;
  }

  .trip-review-heading {
    margin: 5px 0px !important;
  }

  .hotel-bok {
    height: 56% !important;
  }

  .tripPackage-review-image {
    width: 100% !important;
    height: 100% !important;
    border-radius: 8px !important;
  }

  /* ----------Community---------- */

  .login-page {
    padding: 60px 0px !important;
  }

  /* -----------Restaurants & Cafe-------------------- */

  .restaurant-cafe-img {
    height: 180px !important;
  }

  #restaurant-bread {
    position: absolute;
    left: 68px;
    top: 40px;
  }

  #popular-dishes {
    position: relative;
    left: -50px;
  }

  .restaurant-div-mb {
    margin-bottom: 10px !important;
  }

  /* ----------Things-todo---------------- */

  .things-todo-topImg {
    height: 180px !important;
  }

  #things-todo-bread {
    position: relative;
    left: 92px;
    top: 53px;
    width: 55%;
  }

  .things-todo-Divmb {
    margin-bottom: 10px !important;
  }

  .big_img_dispaly {
    height: 100% !important;
  }


  /* --------Nav-Notification----------- */

  .nav-notification {
    width: 93%;
    padding: 10px;
    position: absolute;
    right: 8px;
    top: 38%;
  }



}





@media screen and (max-width:799px) {


  .admin-login {
    padding: 60px 0px !important;
    width: 100% !important;
  }




  /* ----------AboutUS--------- */

  .about-top-activity {
    background-attachment: local !important;
    height: 180px !important;
  }

  .bread {
    position: absolute;
    left: 127px;
    top: 68px;
  }

  .activity-bg::before {
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 30%) !important;
    height: 302px !important;
  }

  .about-mb-main {
    margin-bottom: 15px !important;
  }

  .about-right-Mdiv {
    margin-bottom: 10px !important;
  }

  .about-right-img {
    height: 100% !important;
  }

  .about-counter {
    padding: 10px 0px !important;
    margin-bottom: 10px !important;
  }


  /* --------Contact------------- */

  .contact-top-activity {
    height: 180px !important;
  }

  .contact-div-mb {
    margin-bottom: 15px !important;
  }

  .contact-travel-div {
    margin-bottom: 0px !important;
  }

  .contact-form {
    padding: 20px 5px !important;
  }



  /* -----------blog------------- */

  .blog-top-activity {
    background-attachment: local !important;
    height: 177px !important;
  }

  .blog-pad {
    padding: 0px !important;
  }

  /* -----------Privacy------------ */

  .Privacy-top-activity {
    background-attachment: local !important;
    height: 180px !important;
  }

  #bread-privacy {
    position: absolute;
    left: 82px;
    top: 56px;
  }

  .privacy-div-mb {
    margin-bottom: 10px !important;
  }

  .policy-div-mb {
    margin-bottom: 0px !important;
  }

  .policy {
    margin-bottom: 10px !important;
  }

}