@media screen and (max-width:799px) {
    
    .selectVendor-main{
        height: 100% !important;
        padding: 10px !important;
    }
    .stay-div{
        width: 100% !important;
        margin: 0 !important;
        margin-bottom: 5px !important;
    }


    /* -------stays------ */

    .vendor-login{
        padding: 60px 0px !important;
        /* height: 100% !important; */
        width: 100% !important;
    }

    .login-bg{
        width: 100% !important;
        padding: 40px !important;
        margin: 0 !important;
    }

}