/* .App {
  text-align: center;
} */

@import "~react-image-gallery/styles/css/image-gallery.css";

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.eyew{
  width: 200px !important;
}
/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #ec3198;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Scroll bar start */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d65674;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ce4141;
}

/* Scroll bar end */
/* ----------- */

p {
  margin-bottom: 0px !important;
}

body {
  /* font-family: "Noto Sans", sans-serif !important; */
  font-family: math !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif !important;
  line-height: normal !important;
}

.margin-bottom {
  margin-bottom: 60px;
}

.bhRnqO {
  background: #e08991 !important;
}

.kVtMtA {
  background: #e08991 !important;
}

/* ------------ */

/* Navbar Start*/

.nic .bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: #d81d4a !important;
}

.navigation .nav-link {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  padding-right: 14px !important;
}

.navigation .nav-link:hover {
  color: #000 !important;
}

.navigation .nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background: #000;
  transition: width 0.3s;
}

.navigation .nav-link:hover::after {
  width: 100%;
}

.login-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.5s ease 0s;
  animation: 5s ease 0s infinite normal both running roatepro;
}

@keyframes roatepro {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.small-text {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.685);
}

.advison-name {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  text-overflow: ellipsis;
}

.top-navbar {
  font-size: 12px;
  /* background: rgb(0, 0, 0); */
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  cursor: pointer;
}

.top-navbar-btn {
  padding: 5px 5px !important;
  height: 30px;
  font-size: 13px !important;
  border: none !important;
}

.sign-in-btn {
  background-color: rgb(216 29 74) !important;
}

/* Navbar End */

/* Header Start */

.ghshg_000 .rdrCalendarWrapper {
  position: absolute !important;
  right: 389px !important;
  z-index: 999 !important;
}

.ghshasdg_00 .rdrCalendarWrapper {
  position: absolute !important;
  right: 363px !important;
  z-index: 999 !important;
}

.tourz-search {
  text-align: center;
}

.tourz-search h1 {
  font-size: 56px;
  padding-bottom: 10px;
  color: rgb(0, 0, 0);
  font-weight: 700;
}

.tourz-search p {
  font-size: 20px;
}

.boxes {
  display: flex;
  gap: 15px;
  align-items: end;
  justify-content: space-evenly;
}

.picker {
  height: 55px;
  width: 118px;
  padding: 14px;
}

.header-search {
  background: linear-gradient(39deg, rgb(216, 29, 74), rgb(249 3 162) 80%);
  color: rgb(255, 255, 255);
  border: 0px solid rgb(255, 167, 120) !important;
  padding: 5px 15px;
  font-size: 18px;
  letter-spacing: 1px;
  transition: all 0.5s ease 0s;
  border-radius: 5px;
  font-weight: 600;
  font-family: "Noto Sans";
  text-align: center;
  width: 134px;
  height: 55px;
  display: block;
  line-height: 38px;
  outline: none;
}

.form-field {
  /* float: left; */
  /* padding: 20px 15px; */
  border-right: 1px solid #e6e6e6;
  font-size: 18px;
}

.field-input {
  font-size: 16px;
  position: relative;
  z-index: 2;
  width: 100%;
  border: 0;
  outline: none;
  color: #d81d4a;
  background-color: transparent;
  font-weight: 600;
  border-bottom: 1px dashed #d81d4a;
}

.field-select {
  position: relative;
  z-index: 2;
  width: 20%;
  border: 0;
  outline: none;
  color: #d81d4a;
  background-color: transparent;
  font-weight: 600;
  border-bottom: 1px dashed #d81d4a;
}

.field-select-1 {
  position: relative;
  z-index: 2;
  width: 27%;
  border: 0;
  outline: none;
  color: #d81d4a;
  background-color: transparent;
  font-weight: 600;
  border-bottom: 1px dashed #d81d4a;
}

.form-field label span {
  font-size: 18px;
  color: #d81d4a;
  font-weight: 600;
}

.form-field label {
  font-size: 13px;
}

.field-destination {
  width: 350px;
}

.field-destination-1 {
  width: 250px;
}

.field-date {
  width: 120px;
}

.form-field .field-select .select span {
  color: #d81d4a;
  font-weight: 600;
  border-bottom: 1px dashed #4da981;
  line-height: 31px;
}

.form-field .field-select .select select {
  font-weight: 600;
  color: #d81d4a;
}

.select span {
  display: block;
  padding-right: 20px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.select select {
  cursor: pointer;
  /* position: absolute; */
  width: 100%;
  border: none;
  outline: none;
  background: none;
  color: #d92b55;
}

.form-cn {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 20px 30px 30px 30px;
  border-top: 5px solid #d81d4a;
  position: relative;
}

/* .mainbutton div {
position: absolute;
    bottom: -17px;
    left: 50%;
    content: '';
    border: 17px solid transparent;
    border-bottom-color: #59c45a;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
} */

/* 
.mainbuttonn.btn.btn-primary {
  position: absolute;
  bottom: -17px;
  left: 50%;
  content: '';
  border: 17px solid transparent;
  border-bottom-color: #59c45a;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
} */

.cat {
  display: flex;
  /* gap: 10px; */
  position: absolute;
  top: -34px;
  right: 286px;
  z-index: 9;
}

.sgdfhsg_0 .carousel-control-next {
  display: none !important;
}

.sgdfhsg_0 .carousel-control-prev {
  display: none !important;
}

.cat div {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  gap: 5px;
  width: 145px;
  align-items: center;
  border-right: 1px solid #fff;
  cursor: pointer;
}

.cat p {
  color: #fff;
  display: block;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

.cat span {
  color: #fff;
  font-size: 20px;
}

.carousel-indicators {
  display: none !important;
}

.arrow-icon-1 {
  position: absolute;
  top: -27px;
  right: 72.2%;
  color: #d81d4a;
}

.arrow-icon-2 {
  position: absolute;
  top: -27px;
  right: 57%;
  color: #d81d4a;
}

.arrow-icon-3 {
  position: absolute;
  top: -27px;
  right: 43%;
  color: #d81d4a;
}

.arrow-icon-4 {
  position: absolute;
  top: -27px;
  right: 29%;
  color: #d81d4a;
}

/* Header End */

/* Home start */

.package-btn {
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 60px;
  border-radius: 2px;
  text-decoration: none;
  display: inline-block;
  color: rgb(255, 255, 255);
  font-size: 13px;
  transition: all 0.5s ease 0s;
  background: rgb(0, 0, 0);
  border: 2px solid rgb(0, 0, 0);
}

.package-btn:hover {
  background-color: #fff;
  color: #000;
}

.sub-head {
  font-size: 25px;
  margin-bottom: 20px;
}

.main-head {
  font-size: 32px;
  font-weight: 500;
  text-overflow: ellipsis;
}

.package-img {
  width: 200px;
  margin-top: 23px;
  height: 150px;
  border-radius: 20px;
}

.packages {
  position: relative;
  /* border-radius: 45px; */
  border-radius: 15px;
  padding: 13px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 12px -3px;
  width: 245px;
}

.package-1 {
  background-image: linear-gradient(225deg,
      rgb(240, 240, 255) 0%,
      rgb(227, 210, 255) 99%);
}

.package-2 {
  background-image: linear-gradient(225deg,
      rgb(255, 237, 254) 0%,
      rgb(255, 210, 210) 99%);
}

.package-3 {
  background-image: linear-gradient(135deg,
      rgb(253, 252, 251) 0%,
      rgb(226, 209, 195) 100%);
}

.package-4 {
  background-image: linear-gradient(to top,
      rgb(204, 237, 208) 0%,
      rgb(238, 255, 236) 100%);
}

.package-5 {
  background-image: linear-gradient(to top,
      rgb(234, 241, 184) 0%,
      rgb(250, 255, 218) 100%);
}

.home-all-headings {
  text-transform: capitalize;
  font-size: 44px;
  color: rgb(37, 61, 82);
}

.home-all-headings span {
  color: rgb(244, 54, 79);
  font-size: 44px;
  font-weight: 600;
}

.destination-img {
  width: 100%;
  height: 300px;
  border-radius: 15px;
  image-rendering: pixelated;
}

.gnjet {
  right: 26px;
  position: absolute;
  bottom: 12px;
  color: white;
  text-align: end;
}

.kaka::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top,
      rgba(177, 127, 127, 0.82) 10%,
      rgba(84, 84, 84, 0.35) 80%);
  top: 0px;
  bottom: 0px;
  left: 11px;
  width: 96.5%;
  border-radius: 10px;
}

.mamu::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top,
      rgba(0, 0, 0, 0.82) 10%,
      rgba(84, 84, 84, 0.35) 80%);
  top: 0px;
  bottom: 0px;
  left: 11px;
  width: 93%;
  border-radius: 10px;
}

.chacha::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top,
      rgb(0 0 0 / 54%) 10%,
      rgba(84, 84, 84, 0.35) 80%);
  top: 0px;
  bottom: 0px;
  width: 32%;
  border-radius: 10px;
}

.sc-dnqmqq.bVoXXg .chacha-text {
  position: relative;
  padding-top: 225px;
}

.chacha-text span {
  font-size: 18px;
  font-weight: 600;
  background: rgb(239, 149, 15);
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.color-btn {
  background: linear-gradient(39deg, rgb(68, 68, 189), rgb(3, 158, 249) 80%);
  color: rgb(255, 255, 255);
  border: 0px solid rgb(255, 167, 120);
  padding: 5px 15px;
  font-size: 18px;
  letter-spacing: 1px;
  transition: all 0.5s ease 0s;
  border-radius: 5px;
  font-weight: 600;
  margin: auto;
  width: 65%;
  display: block;
  line-height: 38px;
  outline: none;
}

.chacha {
  overflow: hidden;
}

.box-1:hover {
  scale: 1.2;
  transition: all 1s ease 0s;
}

.discount-bg::before {
  content: "";
  position: absolute;
  background: linear-gradient(to right,
      rgba(22, 40, 105, 0.91) 46%,
      rgba(0, 0, 0, 0.41) 100%);
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.discount-text {
  position: relative;
  color: #fff;
}

.discount-head {
  font-size: 50px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  width: 100%;
  display: block;
  line-height: 58px;
  padding-top: 30px;
  text-transform: uppercase;
  font-family: "Playfair Display", serif;
  text-align: center;
}

.discount-price {
  font-size: 66px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  width: 100%;
  display: block;
  line-height: 46px;
  margin-bottom: 30px;
  text-align: center;
}

.discount-icon {
  cursor: pointer;
  text-align: center;
}

.discount-icon img {
  padding: 12px;
  border-radius: 15px;
  width: 60px;
  height: 60px;
  background: rgb(244, 54, 79);
  display: inline-block;
}

.discount-icon span {
  color: rgb(236, 222, 224);
}

.discount-star {
  background: rgb(244, 54, 79);
  height: 46px;
  width: 145px;
  padding-top: 0px;
  padding-left: 5px;
  border-radius: 2px;
  margin: auto;
}

.bqyzQp {
  height: 315px !important;
}

.discount-carousel {
  height: 350px;
  width: 100%;
  image-rendering: pixelated;
}

.go-bg {
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-transform: uppercase;
  width: 150px;
  height: 150px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  padding: 25px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.52) 0px 1px 20px;
  transition: all 0.5s ease 0s;
  margin: auto;
}

.go-bg:hover {
  transform: rotate(360deg);
  transition: all 0.5s ease 0s;
  cursor: pointer;
}

.go {
  font-size: 45px;
  text-transform: capitalize;
  color: rgb(244, 54, 79);
  font-weight: 600;
}

.go-stay {
  line-height: 20px;
  letter-spacing: 5px;
  border-bottom: 1px solid;
  color: rgb(51, 51, 51);
  margin-top: 25px;
}

.discount-offer {
  color: rgb(255, 255, 255);
  font-weight: 600;
  display: inline-block;
  line-height: 46px;
  width: 300px;
  height: 300px;
  background: rgb(244, 54, 79);
  border-radius: 50%;
  padding: 50px;
  text-align: center;
  border: 7px solid rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.52) 0px 1px 20px;
  transition: all 0.5s ease 0s;

  position: absolute;
  right: 50px;
  top: 114px;
}

.discount-offer:hover {
  background: rgb(244, 186, 54);
  transition: all 0.5s ease 0s;
}

.or-1 {
  font-size: 34px;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.or-2 {
  font-size: 75px;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.or-3 {
  font-size: 34px;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.or-4 {
  font-size: 14px;
  /* text-transform: capitalize; */
  color: rgb(255, 255, 255);
  font-weight: 600;
  border: 1px solid rgb(251, 175, 185);
  padding: 6px;
  border-radius: 25px;
}

/* top sight */
.top-sight img {
  width: 270px;
  height: 230px;
  border-radius: 5px;
  image-rendering: pixelated;
  box-shadow: rgba(0, 0, 0, 0.29) 1px 3px 10px;
}

.top-sight h3 {
  color: rgb(37, 61, 82);
  font-size: 18px;
  text-transform: uppercase;
  line-height: 34px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600;
}

.top-sight h3 span {
  color: rgb(37, 61, 82);
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14.5px;
}

.top-sight p {
  color: rgb(37, 61, 82);
  padding: 10px 0px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 14px;
}

.pink-btn {
  font-weight: 500;
  padding: 8px 25px !important;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease 0s;
  color: rgb(255, 255, 255);
  border: 1px solid #e92c45 !important;
  background: #e92c45;
  letter-spacing: 1px;
  text-transform: uppercase;
  --bs-btn-bg: #e92c45 !important;
}

.pink-btn:hover {
  color: #e92c45 !important;
  border: 1px solid #e92c45 !important;
  background-color: #fff !important;
}

.pink-btn1 {
  font-weight: 500;
  padding: 8px 25px;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease 0s;
  color: rgb(255, 255, 255);
  border: 1px solid #e92c45 !important;
  background: #e92c45 !important;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.pink-btn__2 {
  font-weight: 500;
  padding: 8px 25px;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease 0s;
  color: #e92c45;
  border: 1px solid #e92c45 !important;
  background: #fcfafa;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.jdhfg_01 h2 {
  font-size: 16px !important;
  margin-bottom: 0px !important;
}

/* top sight end */
/* TOP BRANDING */

.brand-head {
  background: linear-gradient(39deg, rgb(0, 184, 255), rgb(1, 161, 255) 80%);
  color: rgb(255, 255, 255);
  padding: 10px 0px;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
}

.brand-head h5 span {
  color: rgb(255, 193, 7);
  font-weight: 600;
  font-size: 18px;
}

.top-brand {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* ----------------------- */
.top-hotels-city {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: start;
}

/* ----------------------- */

.top-brand img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.top-brand h5 {
  margin-top: 0px;
  margin-bottom: 2px;
  color: rgb(52, 60, 66);
  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  transition: all 0.5s ease 0s;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.top-brand span {
  font-size: 13px;
  color: rgb(153, 153, 153);
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.rate {
  border: 1px solid rgb(139, 155, 169);
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 12px;
  color: rgb(37, 61, 82);
  font-weight: 600;
  text-shadow: rgba(0, 0, 0, 0.28) 0px -1px 0px;
  height: 22px;
}

/* MOBILE APP */

.mobile-app h1 {
  font-size: 48px;
  font-weight: 600;
}

.mobile-app p {
  font-size: 15px;
}

.mobile-app ul {
  list-style: none;
  padding: 5px;
}

.mobile-app ul li i {
  color: rgb(255, 255, 255);
  width: 31px;
  /* height: 18px; */
  background: rgb(141, 206, 212);
  border-radius: 3px;
  text-align: center;
  padding: 0px;
  font-size: 20px;
  vertical-align: text-bottom;
}

.img,
svg {
  vertical-align: none;
}

/* Blue bg Content */
.tips-head {
  background: rgb(46, 165, 211);
  display: inline-block;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  padding: 5px 8px 3px;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px;
}

.tips img {
  width: 30%;
  height: 80px;
}

.aggreement-img {
  border-radius: 15px;
}

/* Home End */

/* Footer Start */

.footer-1 {
  background: rgb(0, 0, 0);
  padding: 60px 0px 50px;
}

.disco h3 {
  color: rgb(255, 235, 59);
  font-size: 38px;
  margin-top: 0px;
  font-weight: 700;
}

.disco h3 span {
  color: rgb(255, 255, 255);
  font-size: 16px;
}

.location {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.location li {
  margin: 0px 0px 7px;
}

.location li a {
  color: rgb(209 195 195);
  text-decoration: none;
  background: rgb(45, 45, 45);
  padding: 5px;
}

.footer-nav li a {
  text-decoration: none;
  color: rgb(172 173 175 / 52%);
}

.copy p {
  text-align: center;
  padding: 5px;
  font-size: 14px;
  color: rgb(0, 0, 0);
}

/* Footer End */

/* Login Start */
.login-bg {
  margin: auto;
  width: 50%;
  background: rgb(255, 255, 255);
  padding: 40px 50px 50px;
  border-radius: 5px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 4px 20px;
  margin-top: var(--topspac);
  max-width: 900px;
}

.login {
  text-align: center;
}

.login h4 {
  font-size: 42px;
  margin-bottom: 8px;
  text-align: center;
  font-weight: 700;
  color: #0000007c;
}

.login-input {
  border: 1px solid #000000a6 !important;
}

.login-social {
  padding: 5px;
  list-style: none;
  display: flex;
  gap: 10px;
}

.login-social li {
  width: 33.333%;
}

.login-social li a {
  display: block;
  color: rgb(255, 255, 255);
  padding: 7px 18px;
  text-align: center;
  border-radius: 2px;
  text-decoration: none;
}

.login-social li .fb {
  background: rgb(59, 89, 152);
}

.login-social li .twit {
  background: rgb(0, 172, 237);
}

.login-social li .gogle {
  background: rgb(211, 72, 54);
}

/* Login End */

/* Activity Start */

.bn632-hover {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 56px;
  display: flex;
  border: 1px solid #fff;
  background-size: 300% 100%;
  transition: all 0.4s ease-in-out;
  align-items: center;
  justify-content: center;
}

.bn632-hover:hover {
  background-position: 100% 0;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.bn632-hover:focus {
  outline: none;
}

.bn632-hover.bn28 {
  background-image: linear-gradient(to right,
      #f7186a,
      #ed6ea0,
      #fbb03b,
      #ec8c69);
  box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
}

.agsjhj_01 .bqyzQp {
  height: 55px !important;
}

.activity-h2 {
  background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.activity-img {
  width: 95%;
  height: 400px;
  image-rendering: pixelated;
  border-radius: 100px 0px 100px;
}

.activity-cart {
  width: 550px !important;
  background-color: #f9f9f9;
  padding: 17px 22px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  margin-bottom: 15px;
  position: relative;
  background-image: linear-gradient(to right,
      #f7186a,
      #ed6ea0,
      #fbb03b,
      #ec8c69);
  box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
}

.activity-cart p {
  text-overflow: ellipsis;
}

.activity-cart-1 {
  width: 420px !important;
  background-color: #f9f9f9;
  padding: 17px 22px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  margin-bottom: 15px;
  position: relative;
  /* background-color: #dfb682 !important; */
  background-image: linear-gradient(to right,
      #f7186a,
      #ed6ea0,
      #fbb03b,
      #ec8c69);
  box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
}

.activity-cart-1 p {
  text-overflow: ellipsis;
}

.activity-table tr th {
  background-color: #d81d4a !important;
  color: #fff;
  border-bottom: none !important;
}

.activity-bg::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 60%);
  top: 0px;
  bottom: -27px;
  left: 0px;
  width: 100%;
  height: 472px;
}

.activity-text {
  position: relative;
  color: #fff;
}

.bread {
  position: absolute;
  left: 150px;
  top: 250px;
}

.breadcrumb-active {
  text-decoration: none;
  color: #fff;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 300px !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 25px !important;
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: unset !important;
  image-rendering: pixelated;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 45px;
  object-fit: cover;
  image-rendering: pixelated;
}

/* 


.activity-img::after {
  content: "";
  position: absolute;
  width: 88%;
  height: 75%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  animation-duration: 1s;
  animation-name: float;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-play-state: running;
  background-color: #d81d4a;
} */

.bg-yellow img {
  width: 150px;
  height: 150px;
  image-rendering: pixelated;
  margin-bottom: 10px;
}

.activity-offer {
  background: rgb(255, 152, 0);
  position: absolute;
  padding: 4px 8px;
  /* right: 0px; */
  left: 0px;
  border-radius: 1px;
  color: rgb(255, 255, 255);
  top: 0px;
  font-size: 13px;
}

.bhjbsdf_0 {
  display: flex;
  /* gap: 60px; */
  align-items: center;
  justify-content: space-between;
}

.bhjbsdf_0 s {
  font-size: large;
}

.bhjbsdf_0 span {
  font-size: 14px;
}

/* Activity End */

/* Booking Page Start */
.siri h5 {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 4px;
  font-weight: 100;
}

.siri h1 {
  margin: 0px 0px 20px;
  color: rgb(255, 255, 255);
  font-size: 48px;
  font-weight: 600;
}

.siri p {
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 100;
  line-height: 30px;
}

/* Booking Page End */

/* Hotel Starts here */

.scroll-cat::-webkit-scrollbar-track {
  box-shadow: none !important;
}

.scroll-cat::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}

.css-187mznn-MuiSlider-root {
  color: #d81d4a !important;
}

.hotel-cat {
  display: flex;
  position: absolute;
  top: 76px;
  z-index: 9;
  left: 43%;
}

.hotel-cat div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 145px;
  align-items: center;
  /* border-right: 1px solid #fff; */
  cursor: pointer;
}

.hotel-cat p {
  color: #fff;
  display: block;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.hotel-cat span {
  color: #fff;
  font-size: 20px;
}

.hotel-arrow-icon-1 {
  position: absolute;
  top: -27px;
  right: 50.5%;
  color: #d81d4a;
}

.hotel-filter {
  padding: 0px 5px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.hotel-filter img {
  width: 30px;
  height: 30px;
}

.hotel-filter span {
  font-weight: 600;
}

.hotel-filter-btn {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 190px;
  height: 42px;
  padding: 0px !important;
}

.urus_0 {
  width: 300px;
  height: 200px;
}

.breadcums ul {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
}

.breadcrumbs {
  display: flex;
  list-style: none;
}

.breadcrumbs li {
  font-size: 18px;
  color: #888888;
  font-style: italic;
  border-bottom: 1px solid #888888;
}

.breadcrumbs a {
  text-decoration: none;
  color: #888888;
}

.main_hotel {
  border: 1px solid #888888;
}

/* 
.search-result {
  background-color: #111111;
  padding: 10px 30px;
  width: 100%;
  height: 110px;
  position: relative;
}

.search-result p {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  line-height: 35px;
  font-family: "Georgia";
  font-style: italic;
}

.search-result p ins {
  font-size: 30px;
  text-decoration: none;
  color: #59c45a;
}

.search-result p span {
  font-size: 13px;
} */

.hotel_content section {
  border-bottom: 1px dotted #888888;
}

.star_rating select {
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-bottom: 1px dotted #db1b52 !important;
  padding-bottom: 5px;
}

.vi_0 {
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-bottom: 1px dotted #d81d4a !important;
  outline-width: 0px;
  padding: 7px;
  border-radius: 5px;
  width: 100%;
}

.sidebar {
  border-right: 1px dotted black;
  /* padding-right: 10px; */
}

.hotel-name a {
  color: #111111;
  font-size: 30px;
  line-height: 32px;
  font-weight: 300;
  text-decoration: none;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.hotel-star-address span {
  font-size: 15px;
}

.vi_1 {
  border: 1px solid #888888;
  outline-width: 0px;
  padding: 7px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.hotel-head {
  color: #d81d4a;
  font-weight: 600;
}

.out_0 h6 {
  color: #d81d4a;
  font-weight: 600;
  font-size: 20px;
  font-family: math !important;
}

.out_0 h6 span {
  color: #888888;
  font-size: 16px;
}

.out_0 p {
  font-size: 18px;
  color: #000000ad;
}

.out_0 p s {
  font-size: 14px;
}

.warner {
  border: 1px solid #0000002e;
  padding: 5px;
  border-radius: 7px;
  width: 155px;
}

.warner label {
  font-weight: 600;
  color: #d81d4a;
}

.warner-out {
  border: 1px solid #0000002e;
  padding: 5px;
  border-radius: 7px;
  margin-bottom: 10px;
}

.warner-out div {
  cursor: pointer;
}

.warner-out p {
  font-weight: 600;
  color: #d81d4a;
}

.increase-icon {
  cursor: pointer;
  border: 1px solid #0000007d;
  border-radius: 50%;
}

.amenties-modal .modal-content {
  max-height: 500px !important;
  overflow: auto !important;
}

.bhsagd_1 {
  cursor: pointer;
  border: 1px solid;
  width: fit-content;
  padding: 4px;
  border-radius: 8px;
}

.sgdffhjd_0 .carousel-control-next-icon {
  background-color: #e92c45;
  border-radius: 10px;
}

.sgdffhjd_0 .carousel-control-prev-icon {
  background-color: #e92c45;
  border-radius: 10px;
}

.yellow-btn {
  padding: 8px 25px;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease 0s;
  color: rgb(0, 0, 0);
  border: 1px solid #efa025 !important;
  background: #efa025;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.yellow-btn:hover {
  background-color: #fff;
  border: 1px solid #efa025 !important;
  color: #efa025;
}

.prpt-nights {
  align-items: center;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 9px;
  color: #757575;
  display: inline-flex;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  padding: 0px 9px;
  text-transform: uppercase;
}

.hotel-bok {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 12px;
  padding: 15px;
  /* min-height: 225px;
  height: 320px; */
}

.vasghd_0 {
  background-color: #f2f2f2;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: space-between;
  padding: 10px 1px;
  margin-bottom: 10px;
}

/* Hotel Ends here */

/* Taxi start from here */

.banner_hero {
  position: relative;
}

.jitotrg {
  margin-bottom: 326px;
}

.local-00 {
  border: 1px solid rgb(255, 255, 255);
  width: inherit;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 13%) 0px 0.2rem 1.2rem 0px;
  position: sticky;
  top: 0;
  z-index: 6;
  border-radius: 1.2rem;
  padding: 1.5rem;
  margin: auto;
  height: auto;
}

.ul_wrap {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  flex-direction: row;
  border-radius: 30px;
  padding: 0px 6px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin: 11px;
  cursor: pointer;
}

.banner_hero {
  position: relative;
}

.geh {
  position: absolute;
  top: -90px;
  width: 85%;
}

.tab-active {
  background-image: linear-gradient(to right,
      #f7186a,
      #ed6ea0,
      #fbb03b,
      #ec8c69);
  box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
  border-radius: 30px;
  color: #fff !important;
  padding: 2px 11px;
}

.ul_wrap li {
  margin: 15px 0px 15px;
  font-size: 16px;
  font-weight: 700;
}

.docab {
  font-size: 30px;
  font-weight: 600;
  color: #000;
  text-align: center;
}

.wr-9 tr th {
  background-color: #d81d4a;
  color: #fff;
}

.hotel-details-img img {
  width: 340px;
  height: 200px;
  border-radius: 15px;
}

.dnasj_01 p {
  color: #111111;
  /* font-size: 32px; */
  /* line-height: 36px; */
}

.dnasj_01 span {
  font-size: 16px;
  color: #000000bf;
}

.dnasj_01 ul {
  display: flex;
  flex-wrap: wrap;
  gap: 4px 30px;
  font-size: 13px;
  padding-left: 15px;
}

.smith {
  border: 1px solid #0000002e;
  padding: 5px;
  border-radius: 7px;
  height: 65px;
}

.smith label {
  font-weight: 600;
  color: #d81d4a;
}

.ola-box {
  /* width: 500px; */
  border: 1px solid #00000040;
  padding: 10px;
  height: fit-content;
}

.ola {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* gap: 50px; */
}

.ola img {
  width: auto;
  height: 50px;
}

.ola p {
  font-size: 14px;
  color: #000000b4;
  text-overflow: ellipsis;
}

.ola span {
  font-weight: 600;
  font-size: 18px;
}

.ola-link {
  text-decoration: none;
  color: #000;
}

.ola-content {
  width: 200px;
}

.taxi-book {
  display: flex;
  gap: 30px;
  line-height: 40px;
  justify-content: space-between;
  align-items: center;
}

.taxi-book span {
  font-size: 12px;
  color: #000000c4;
  text-overflow: ellipsis;
}

.taxi-book p {
  text-overflow: ellipsis;
}

.lalit {
  border: 1px solid #00000052;
  margin-top: 75px !important;
  height: fit-content;
}

.lalit-left {
  padding: 0px !important;
  border-right: 1px solid #00000052;
}

.lalit h5 {
  font-size: large;
  background: #d81d4a;
  padding: 8px;
  color: #fff;
  text-overflow: ellipsis;
}

.munna img {
  width: 100%;
  height: 160px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.munna span {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.munna p {
  text-align: justify;
  color: rgb(134, 134, 134);
}

/* Taxi End */

/* Profile Start */

.profile-name {
  font-weight: 600;
  font-size: 27px;
  text-align: center;
  line-height: 26px;
}

.wad_0 h4 {
  background: #d81d4a;
  padding: 15px;
  color: rgb(255, 255, 255);
  border-radius: 3px 3px 0px 0px;
}

.pay-done {
  background: rgb(62, 142, 65) !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px 4px;
  width: 40px;
}

.pay-more {
  background: rgb(216 29 74) !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px 4px;
  width: 68px;
  cursor: pointer;
}

.pay-pending {
  background: rgb(244, 163, 33) !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px 4px;
  width: 56px;
}

.yue_0 {
  list-style: none;
  padding: 5px;
}

.yue_0 li {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #00000049;
  padding: 10px 0px;
}

.yue_0 li img {
  width: auto;
  height: 30px;
  border-radius: 50%;
}

.yue_0 li span {
  font-size: 12px;
  text-overflow: ellipsis;
  color: #000000a8;
}

.sdhjf_12 h2 {
  font-size: 16px !important;
}

/* Profile End */

/*Tour Packages start  */
.trip-img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  margin-bottom: 8px;
}

.trip h4 {
  color: #d81d4a;
  font-family: math !important;
  font-weight: 600;
  font-size: 30px;
}

.trip p {
  color: #00000080;
}

.trip-amount {
  display: flex;
  justify-content: center;
  position: relative;
}

.trip-amount p {
  color: #00000080;
}

.trip-amount span {
  color: #d81d4a;
  font-weight: 600;
  font-size: 20px;
}

.trip-offer {
  background: rgb(255, 152, 0);
  position: absolute;
  padding: 4px 8px;
  right: 0px;
  border-radius: 1px;
  color: rgb(255, 255, 255);
  top: -10px;
  font-size: 13px;
}

.ousf_0 {
  border: 1px solid rgb(222, 222, 222);
  background: rgb(255, 255, 255);
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease 0s;
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.ousf_0:hover {
  box-shadow: rgba(0, 0, 0, 0.23) 0px 5px 19px -3px;
  transform: scale(1.02);
}

.trip-i {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  padding: 5px;
  list-style: none;
}

.trip-i img {
  width: 20px;
}

.trip-i li {
  text-align: center;
  padding: 6px;
  border: 1px solid #00000040;
  width: fit-content;
}

.trip-i p {
  color: #00000073;
  font-size: 12px;
}

.css-wjh20t-MuiPagination-ul {
  justify-content: end;
  margin-bottom: 10px !important;
}

.umbrella {
  width: 20px;
  height: fit-content;
}

.trip-gallery {
  width: 70%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 10px;
}

.small_img {
  display: flex;
}

.small_img div img {
  width: 70px !important;
  height: 70px;
  margin-left: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.owl-mama {
  width: 580px !important;
}

.sjrhg_0 tr td {
  color: #00000096;
  text-overflow: ellipsis;
}

/*Tour Packages End  */

/* Communtiy start  */

.post-model .modal-content {
  max-height: 600px !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}

.facebook {
  /* position: fixed; */
  /* bottom: 0; */
  left: 0;
  /* width: 100vw; */
  background-color: #d81d4a;
  height: 80px;
  margin-bottom: 30px;
}

.face-search {
  height: 38px;
  width: 194px;
}

.total-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center !important;
  padding: 10px 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: #fff;
  gap: 10px;
}

.total-post span {
  font-size: 20px;
  font-weight: 600;
}

.face-profile {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
}

.face-profile p {
  color: #fff;
  font-size: 17px;
}

.face-profile span {
  color: #ffffff81;
}

.active-dot {
  position: absolute;
  /* right: 14px; */
  left: 35px;
  top: 5px;
  width: 12px;
  height: 12px;
  border: 3px solid #ffffff;
  border-radius: 100%;
  background: green;
}

.facebook-left {
  width: 82px;
  background-color: #d81d4a;
  padding: 36px 16px;
  text-align: center;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  border-radius: 5px;
  position: -webkit-sticky;
  /* position: sticky;
  z-index: 2;
  top: 100px; */
  margin-bottom: 30px;
}

.facebook-left ul {
  padding: 5px;
  list-style: none;
}

.facebook-left ul li {
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 10px;
  margin-bottom: 30px;
  color: #fff;
  font-size: 20px;
}

.face-story {
  padding: 7px;
  border: 2px solid rgba(3, 137, 201, 0.15);
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ohim {
  background-color: rgb(216 29 74 / 42%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ohim input {
  position: absolute;
  bottom: 41px;
  height: 100px;
  opacity: 0;
  cursor: pointer;
  width: 200px;
}

.ohim p {
  width: 50px;
  height: 50px;
  margin: 73px;
  background-color: rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.face-story-1 {
  padding: 7px;
  border: 2px solid rgba(3, 137, 201, 0.15);
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ohim-1 {
  background: linear-gradient(0deg, #e18d1e 0%, rgba(0, 212, 255, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.fb-name {
  position: absolute;
  bottom: 0;
  padding: 7px 20px;
  color: #fff;
}

.fb-name span {
  font-size: 12px;
  letter-spacing: 1px;
}

.ohim-2 {
  background: linear-gradient(0deg, #ed8079 0%, rgba(0, 212, 255, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ohim-3 {
  background: linear-gradient(0deg, #a38a7b 0%, rgba(0, 212, 255, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ohim-4 {
  background: linear-gradient(0deg, #fcd101 0%, rgba(0, 212, 255, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.jkghsidf_0 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  background: #fff;
}

.feel {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.album {
  display: flex;
  gap: 7px;
  padding: 8px;
  align-items: center;
  font-size: 15px;
  color: grey;
  border: 1px solid #0000002f;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}

.album input {
  position: absolute;
  opacity: 0;
  left: 0;
  cursor: pointer !important;
  width: -webkit-fill-available;
}

.jgashgd_1 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 14px;
  border-radius: 5px;
}

.fb-gallery {
  width: 97px;
  height: 80px;
  object-fit: cover;
}

.save-tag {
  padding: 10px 10px;
  background-color: #d7e8ef;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.kalin {
  margin-bottom: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.emoji {
  padding: 14px 10px;
  border-bottom: 1px solid #e1e7e9;
  border-top: 1px solid #e1e7e9;
  background-color: rgba(237, 247, 251, 0.25);
  display: flex;
  justify-content: space-between;
}

.emoji img {
  width: 25px;
  height: 25px;
}

.react-0 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 14px 0px;
  position: relative;
}

.react-0 span {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  color: grey;
  cursor: pointer;
}

.chat {
  font-size: 15px;
}

.chat-online {
  font-size: 12px;
}

.chat-box li {
  border-bottom: 1px solid #0000002b;
  padding: 6px;
  cursor: pointer;
}

.fb-notification h3 {
  padding: 15px 15px 15px;
  border-bottom: 1px solid #c3c3c3;
  font-size: 20px;
}

.fb-notification {
  width: max-content;
  height: 390px;
  overflow: auto;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: absolute;
  background: #ffe6ec;
  border-radius: 11px;
  transform: translate(-392px, 0px);
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #db2d57 !important;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
}

.fb-notification ul {
  list-style: none;
  padding: 5px;
}

.fb-notification ul li {
  border-bottom: 1px solid #c3c3c3;
  padding: 5px;
}

.jhdjs_0 {
  display: flex;
  gap: 10px;
  align-items: center;
}

.jhdjs_0 span {
  color: #fff;
  background-color: #d81d4a;
  padding: 2px 4px;
  font-size: 20px;
  cursor: pointer;
  border: 1px solid;
  transition: 0.8s ease all;
  border-radius: 5px;
}

.jhdjs_0 span:hover {
  color: #d81d4a;
  background-color: #ffffff;
}

.fb-profile {
  position: relative;
}

.fb-profile span {
  font-size: 28px;
  color: #d81d4a;
  cursor: pointer;
}

.fb-profile input {
  opacity: 0;
  position: absolute;
  width: 36%;
  left: 0;
  bottom: 7px;
  cursor: pointer !important;
}

.fb-profile_1 p input {
  opacity: 0;
  position: absolute;
  width: 36%;
  right: 7px;
  bottom: 24px;
  cursor: pointer !important;
}

.react-multiple-carousel__arrow {
  display: none !important;
}

.open-emoji {
  position: absolute;
  bottom: 39px;
  left: 35px;
  background-color: #ffffff;
  padding: 12px 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  transition: 1s all ease;
}

.open-emoji img {
  width: 20px;
  height: 20px;
  animation: emoji1 0.7333s;
  cursor: pointer;
}

@keyframes emoji1 {
  0% {
    opacity: 0.3374;
    transform: translateY(35.7785px) scale(0.3374, 0.3374);
  }
}

.login-eye {
  position: relative;
  display: flex;
  align-items: center;
}

.login-1-eye {
  /* position: absolute;
  top: 9px;
  right: 20px; */
  font-size: 20px;
  cursor: pointer;
  border: 1px solid;
  padding: 3px;
  border-radius: 0px 5px 5px 0px;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #e92c45 !important;
}

.frnd {
  background-color: #e4e6eb;
  padding: 1px 7px;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 1px;
  gap: 3px;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.animate-logo {
  width: 620px;
  height: 250px;
  object-fit: none;
  image-rendering: pixelated;
}

/* Communtiy End  */

/* about-us Start */
.about-us h3 {
  color: rgb(37, 61, 82);
  font-weight: 600;
}

.about-us h3 span {
  color: #d81d4a;
}

.about-us p {
  color: rgb(136, 136, 136);
  line-height: 30px;
  margin-bottom: 15px !important;
}

.about-us button {
  letter-spacing: 1.5px;
}

.about-counter {
  background: #00afeb;
  padding: 50px 0px;
  background-image: linear-gradient(to top, #4481eb 0%, #00afeb 100%);
}

.about-counter span {
  font-size: 42px;
  font-weight: 600;
  color: rgb(255, 194, 16);
  text-shadow: rgba(0, 0, 0, 0.43) 0px 1px 0px;
}

.about-counter h4 {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: rgba(0, 0, 0, 0.43) 0px 1px 0px;
  color: rgb(255, 255, 255);
  font-family: math !important;
}

.about-counter p {
  color: rgb(237 247 255);
  font-size: 18px;
  font-weight: 300;
}

/* about-us End */

/* Testimonial start */
.testi .bqyzQp {
  height: 200px !important;
}

.testi .bghjHX {
  display: flex !important;
  align-items: center !important;
  height: 200px !important;
}

/* Testimonial end */

/* Blog Start */
.blog img {
  width: 100%;
  height: 300px;
  border-radius: 10px;
}

.blog h4 {
  font-size: 28px;
  font-family: math !important;
}

.blog span {
  color: #0000008c;
  font-size: 14.5px;
  text-align: justify;
}

/* Blog End */

/* Privacy Policy Start */
.policy {
  text-align: center;
  background: rgb(255, 255, 255);
  border: 1px dashed rgb(224, 224, 224);
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  padding: 25px 15px 15px;
  transition: all 0.5s ease 0s;
}

.policy:hover {
  box-shadow: rgba(150, 150, 150, 0.4) 0px 15px 32px -5px;
  transition: all 0.5s ease 0s;
}

.policy span {
  width: 65px;
  height: 65px;
  background: rgb(255, 194, 16);
  border-radius: 50px;
  color: rgb(37, 61, 82);
  padding: 18px 18px;
  font-size: 28px;
  text-align: center;
  transition: all 0.5s ease 0s;
  border: 1px dashed rgb(224, 224, 224);
  text-shadow: rgb(255, 255, 255) 0px 1px, rgb(38, 47, 51) 0px -1px;
}

.policy h4 {
  color: #bf4562;
  margin-top: 25px;
  font-family: math !important;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.policy p {
  font-size: 15px;
  color: #0000008c;
  font-weight: 400;
}

/* Privacy Policy End */

/* Vendor start */
.stay-div {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  cursor: pointer;
}

.stay-div img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.stay-div p {
  text-transform: uppercase;
  font-family: "Ubuntu", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #d81d4a;
  text-align: center;
  letter-spacing: 5px;
  text-shadow: 0 2px 1px #79a06d, -1px 3px 1px #82ad75, -2px 5px 1px #8ebf80;
}

.stay-div span {
  font-size: 14px;
}

.passbtn {
  border: 1px solid dimgray;
  background-color: transparent;
  border-radius: 0px 5px 5px 0px;
}

.savee-btn {
  font-size: 12px;
  background: #0bb10b;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
}

.location-input {
  width: 100px !important;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff;
  background-color: #d81d4a !important;
}

/* Vendor End */

.comment_img img {
  width: 100%;
  height: 240px;
}

/* Restaurant and Cafe Start */
.cafe_img {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  border-radius: 20px;
}

.cafe_img img {
  width: -webkit-fill-available;
  height: 250px;
  border-radius: 20px;
  image-rendering: pixelated;
  margin-bottom: 5px;
}

.cafe_tabss .MuiTabs-flexContainer {
  justify-content: space-around !important;
}

.cafe_tabss .MuiTabs-indicator {
  left: 61.2969px;
}

.cusins p {
  letter-spacing: 1px;
  font-size: 17px;
  font-weight: 600;
  text-align: start !important;
}

.cafe_food_img {
  overflow: hidden;
}

.cafe_food_img img {
  width: 100%;
  height: 150px;
  image-rendering: pixelated;
  transition: transform 1s;
  margin-bottom: 5px;
}

.cafe_food_img img:hover {
  transform: scale(1.2);
  cursor: pointer;
}

/* Restaurant and Cafe End */


/* Things to Do */

.clk_img {
  filter: blur(2px);
  width: 105px;
  height: 66px;
  transition: filter 0.3s ease;
  cursor: pointer;
}

.clk_img:hover {
  cursor: pointer;
  filter: blur(0);
}

.clk_active {
  cursor: pointer;
  filter: blur(0);
}

.clk_img_overF {
  overflow: hidden;
  overflow-x: scroll;
  width: auto;
}


.big_img_dispaly {
  width: 100%;
  height: 370px;
}

.nav-notification {
  width: 21%;
  background-color: #ef9f9f;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  right: 186px;
  top: 17%;
  z-index: 9999;
}