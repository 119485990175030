
.chat-delete-icon{
    position: absolute;
    right: 0px;
    top: 60px;
}

.modal-header .btn-close{
    position: absolute;
    right: 27px;
    top: 25px;
}



/* ------------------ Community Header start --------------- */
.Community-tab-header-mobileview {
    display: none;
}

.notification-icon-navbar {
    display: none;
}


@media screen and (max-width: 992px) {

    .navigation .nav-link {
        margin: 2px;
        color: #fff;
        padding-left: 15px;
        border-radius: 30px 30px 30px 30px;
        font-size: 15px;
        text-align: start;
        /* font-weight: 500; */
        border: 2px solid white;
        letter-spacing: 1px;
        padding-right: 14px !important;
        transition: all .6s ease-in-out;
    }

    .navigation .nav-link:hover {



        background-color: #ffffff75;
        border-radius: 30px 30px 30px 30px;
        text-align: center;

        /* font-weight: 500; */



    }

    .navigation .nav-link:nth-of-type(10) {
        margin: 2px;
        color: #fff;
        padding-left: 5px;
        border-radius: 50%;
        font-size: 15px;
        text-align: center;
        /* font-weight: 500; */
        border: 2px solid rgba(255, 255, 255, 0);
        letter-spacing: 1px;
        padding-right: 14px !important;
    }

    .notification-icon-navbar {
        display: block;
        position: absolute;
        top: 196px;
        right: 15px
    }

    .mobile-res-community-header {
        display: none !important;
    }

    .Community-tab-header-mobileview {
        display: block;

    }



    .button-community-tab-header {
        position: absolute;
        top: 3%;
        right: 10%
    }

    .borderhrlinec {
        border: 2px solid white;
        width: 100%;
    }

    .card-body-header-main div:first-child {
        padding: 0 !important;
    }

    .active-dot-mob-res {
        position: absolute;
        /* right: 14px; */
        left: 25px;
        top: 5px;
        width: 12px;
        height: 12px;
        border: 3px solid #ffffff;
        border-radius: 100%;
        background: green;
    }
}

/* @media screen and (max-width: 799px) {} */

/* ------------------ Community Header end ----------------- */



/* ------------------ Community main start --------------- */



.scroll-cat {
    height: 550px;

    font-size: 16px !important;

}

@media screen and (max-width: 799px) {
   


    .Mobile_responsive-Navbar-community-main {
        display: flex !important;
        overflow: hidden !important;

    }

    .Mobile_responsive-Navbar-community {
        /* position: fixed;
        bottom: 0; */
        width: 100%;

    }

    .class-community-common-height {
        height: 350px !important;
        width: 100%;
        overflow-y: auto;
        margin-bottom: 40px;
    }

    .class-community-common-height::-webkit-scrollbar {
        display: none;
    }

    .facebook-left {
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;
        background-color: #d81d4a;
        position: fixed;
        z-index: 12;
        bottom: 0;
        left: 0;
        border-radius: 0% !important;

    }

    .facebook-left ul {
        display: flex;
        justify-content: space-evenly;
        align-items: end;
        padding: 5px 0 !important;
        margin-bottom: 0 !important;


    }

    .facebook-left ul li {
        padding: 10px 0 !important;
        margin-bottom: 0 !important;

    }

    .animate-logo {
        height: auto;
        padding: 1px;
        width: 300px;

    }

    .css-13xfq8m-MuiTabPanel-root {
        display: flex;
        padding: 2px !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .scroll-cat {
        padding: 10px 3px 10px 10px;
        overflow: scroll !important;
        font-size: 14px !important;



    }

    .facebook {
        margin-bottom: 0 !important;
    }

    .MuiNativeSelect-select {
        width: 50% !important;
    }
}

/* ------------------ Community main ends --------------- */





/* ------------------ Community 2nd menu start --------------- */
@media screen and (max-width: 799px) {
    .mobilerespondive-friend-profile {
        padding: 0 !important;
    }

    .scroll-cat-res-change {
        padding: 0 !important;
    }

    /* .css-heg063-MuiTabs-flexContainer{
        overflow-x: scroll;
    } */

    .jgashgd_1 {
        padding: 10px !important;
    }

    .scroll-cat1 {
        padding: 10px 0.5px;
    }

    .mobileserponsiver-100w {
        width: 100%;
        padding: 1px;
    }
}

/* ------------------ Community 2nd menu end --------------- */


/* =========================instafam gallery friend pofile==================== */

.gallery-main-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* gap: 10px; */

}

.gallery-image-responsive {
    height: 200px;
    width: 200px;
}



/* =========================instafam gallery friend pofile==================== */


/* =========================Chat Pop Up Css==================== */
.border-profile-chat {

    margin: 5px 12px 5px 5px;
    overflow: hidden;

}

.border-profile-chat img {

    border-radius: 4px;
    transition: all 0.3s ease-in-out;

}

.border-profile-chat:hover {
    cursor: pointer;


}


.form-chat-button-controll Button {
    --bs-btn-color: #fff;
    --bs-btn-bg: #d81d4a;
    --bs-btn-border-color: #d81d4a;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #d81d4a;
    --bs-btn-hover-border-color: #d81d4a;
    --bs-btn-focus-shadow-rgb: black;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #d81d4a;
    --bs-btn-active-border-color: #d81d4a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #d81d4a;
    --bs-btn-disabled-border-color: #d81d4a;
}


/* =========================Chat Pop Up Css==================== */



/* -------------------nav bar top------------------- */
.top-navbar-icon{
    height: 15px;
    width: 15px;
}

@media screen and (max-width: 799px) {

    .active-dot {
        position: absolute;
        /* right: 14px; */
        right: 0;
        top: 0;
        width: 12px;
        height: 12px;
        border: 3px solid #ffffff;
        border-radius: 100%;
        background: green;
    }

}