.left-side {
  background-color: #fcfcfc;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 650px;
  top: 0px;
  position: sticky;
  overflow: hidden;
  overflow-y: scroll;
  width: 235px;
}

.side-bar {
  background-color: rgb(216 29 74) !important;
  padding: 20px 0px;
  list-style: none;
  min-height: 600px !important;
  padding-left: 1rem;
}

.side-bar-item {
  display: flex;
  gap: 16px;
  /* justify-content: space-between; */
  align-items: center;
  font-size: 16px;
  text-decoration: none !important;
  color: #fff;
  font-weight: 500;
}

.side-bar-item-0 {
  display: flex;
  gap: 12px;
  /* justify-content: space-between; */
  align-items: center;
  font-size: 16px;
  text-decoration: none !important;
  color: #fff;
  font-weight: 500;
}

/* .side-bar-item:hover {
    font-size: 20px;
} */

.si09 a {
  text-decoration: none;
}

.a-ele {
  margin: 10px 0px;
  background: #dba709;
  margin-right: 30px;
  color: white;
  border-radius: 50px;
  text-align: center;
  padding: 6px;
  width: 185px;
  /* font-size: 9px; */
  cursor: pointer;
}

.side-bar-item-ul {
  list-style: none;
  padding: 0px;
}

.side-bar-item-2 {
  color: #fff;
  font-size: 14px;
  padding: 10px;
  background: #d81d4a;
  margin-top: 12px;
  margin-bottom: 13px !important;
  border-radius: 30px;
}

.header {
  height: 75px;
  background: rgb(216 29 74);
}

.admin-all-btn {
  border-radius: 3px;
  color: white;
  font-size: 18px;
  border: 1px solid;
  background-color: #1e9b1e;
  padding: 5px;
  font-weight: 500;
}

.admin-all-btn:hover {
  background-color: #0093d5;
}

.right-h {
  width: -webkit-fill-available;
}

.edit-btn {
  color: #1e9b1e;
  font-size: 18px;
  cursor: pointer;
}

.delete-btn {
  color: rgba(219, 9, 9, 0.884);
  font-size: 18px;
  cursor: pointer;
}

.all-admin-heading {
  border-bottom: 2px solid #0093d5;
  width: 300px;
  text-align: center;
  color: #1e9b1e;
}

.search-width {
  width: 350px !important;
}

.date-width {
  width: 250px !important;
}

.admin-about-img {
  width: 200px;
  height: 100px;
  border-radius: 10px;
}

/* gk */
.admin-table-head {
  width: max-content;
  text-align: center;
}

.admin-table-head thead th {
  background: #d81d4a !important;
  color: #fff !important;
}

.sdfsd-table-head thead th {
  background: #d81d4a !important;
  color: #fff !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #d81d4a !important;
}

.block-user {
  font-size: 12px;
  background: #b71818;
  color: #fff;
  padding: 7px;
  border-radius: 8px;
  letter-spacing: 1px;
}

.unblock-user {
  font-size: 12px;
  background: #0e891d;
  color: #fff;
  padding: 7px;
  letter-spacing: 1px;
  border-radius: 8px;
}

.blck-icon {
  font-size: 12px;
  color: #d81d4a;
}

.Ldhfh_0 {
  font-size: 14px;
  background: #d81d4a;
  color: #fff;
  letter-spacing: 1px;
  width: 100px;
  margin: auto;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 43px !important;
  border: 1px solid #575757 !important;
}

.css-1hfgrgt-MuiFormControl-root {
  margin: 0px !important;
}

.vendorli .modal-content {
  max-height: 580px;
  overflow: auto;
}

.anties_mdl .modal-content {
  max-height: 500px;
  overflow: auto;
}

.gallery_mdl .modal-content {
  max-height: 500px;
  overflow: auto;
}

.export-btn .button-download {
  border: none !important;
  padding: 6px !important;
  font-size: 12px !important;
  background-color: #dba709 !important;
  border-radius: 10px !important;
  text-transform: uppercase !important;
  letter-spacing: 0.9px !important;
  color: #000 !important;
}

.export-btn_0 {
  border: none !important;
  padding: 6px !important;
  font-size: 12px !important;
  background-color: #dba709 !important;
  border-radius: 10px !important;
  text-transform: uppercase !important;
  letter-spacing: 0.9px !important;
  color: #000 !important;
}

.import-btn {
  border: none !important;
  padding: 6px !important;
  font-size: 12px !important;
  background-color: #149b18 !important;
  border-radius: 10px !important;
  text-transform: uppercase !important;
  letter-spacing: 0.9px !important;
  color: #fff !important;
}

.StarSize .star-svg {
  width: 20px !important;
  height: 20px !important;
}

.Ouhads_01 {
  box-shadow: rgb(141 66 76) 0px 5px 15px;
  height: 90%;
}
.Ouhads_02 {
  box-shadow: rgb(141 66 76) 0px 5px 15px;
  height: 90%;
}
.Ouhads_03 {
  box-shadow: rgb(141 66 76) 0px 5px 15px;
  height: 90%;
}

.Ouhads_01 .pink-btn {
  background-color: #81d81d;
  border: 1px solid #81d81d !important;
}
.Ouhads_02 .pink-btn {
  background-color: #d8bb1d;
  border: 1px solid #d8bb1d !important;
}

.Ouhads_01 .card-header {
  background-color: #81d81d;
}
.Ouhads_02 .card-header {
  background-color: #d8bb1d;
}
.Ouhads_03 .card-header {
  background-color: #d81d4a;
}
